import { lazy } from 'react';
import {
  AVANS_MERCHANT,
  avansVerifyFailed,
  avansVerifySuccess
} from '~/constants/paths/pathAvans';
import logo from '~/images/avansCredit/avansLogo.svg';
import { AvansMain } from '~/pages/AvansCredit/components/AvansMain';

const LayoutAvansCredit = lazy(
  () => import('~/pages/AvansCredit/components/Index')
);
export const avansCreditRoute = {
  path: AVANS_MERCHANT,
  element: (
    <LayoutAvansCredit
      logo={logo}
      phone="0 800 20 25 20"
      merchant="AvansCredit"
    />
  ),
  children: [
    {
      path: 'verify/:id',
      element: (
        <AvansMain
          redirectPaths={{
            success: avansVerifySuccess,
            failed: avansVerifyFailed
          }}
        />
      )
    }
  ]
};
