import { UserData } from '~/utils/formatUserData';

interface PaymentDetailsProps {
  userData: UserData;
}

export const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  userData
}: PaymentDetailsProps) => {
  return (
    <div className="payment-details-container">
      <div className="payment-details-box">
        <div className="payment-details-row">
          <span className="payment-details-label">Призначення:</span>
          <span className="payment-details-value">{userData.description}</span>
        </div>
        <div className="payment-details-row row-bg">
          <span className="payment-details-label">Сума без комісії:</span>
          <span className="payment-details-value">{userData.payAmount}</span>
        </div>
        <div className="payment-details-row">
          <span className="payment-details-label">Комісія складатиме*:</span>
          <span className="payment-details-value">{userData.fee}</span>
        </div>
        <div className="payment-details-row row-bg">
          <span className="payment-details-label">Разом до оплати**:</span>
          <span className="payment-details-value">{userData.fullAmount}</span>
        </div>
      </div>
    </div>
  );
};
