import i18next from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import languageDetector from 'i18next-browser-languagedetector';
import { locates } from '@root/services/locates';

// eslint-disable-next-line import/no-named-as-default-member
i18next
  .use(backend)
  .use(languageDetector)
  .use(initReactI18next)
  .use(ICU)
  .init({
    fallbackLng: 'uk',
    react: {
      useSuspense: false
    },
    lng: localStorage.getItem('i18nextLng') || 'uk',
    resources: locates,
    detection: {
      caches: ['cookie', 'localStorage']
    }
  });
