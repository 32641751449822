import { lazy } from 'react';
import {
  avansVerifyFailed,
  avansVerifySuccess
} from '~/constants/paths/pathAvans';
import { FINBAR_MERCHANT } from '~/constants/paths/pathFinBar';
import logo from '~/images/finBar/FinBar_logo.png';
import { AvansMain } from '~/pages/AvansCredit/components/AvansMain';

const LayoutAvansCredit = lazy(
  () => import('~/pages/AvansCredit/components/Index')
);
export const FinBarRoute = {
  path: FINBAR_MERCHANT,
  element: (
    <LayoutAvansCredit logo={logo} phone="0800 20 10 40" merchant="FinBar" />
  ),
  children: [
    {
      path: 'verify/:id',
      element: (
        <AvansMain
          redirectPaths={{
            success: avansVerifySuccess,
            failed: avansVerifyFailed
          }}
        />
      )
    }
  ]
};
