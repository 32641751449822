import { sendPay, SendPayParams } from '@root/api/getUserInfo';
import { getFakeForm } from '@root/services/fakeForm';
import { formatSendCardNuber, formatSendExpiry } from '@root/utils/convertors';
import { useCallback, useMemo, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { useCheckContext } from '~/context/CheckContext';
import { useFingerPrint } from './useFingerPrint';
import { useUserEncodeData } from './useUserEncodeData';

export type OnSubmitParams = {
  merchant?: string;
  token?: string;
  redirectPaths: {
    success?: (param?: string) => string;
    failed: (param?: string) => string;
  };
};

export type OnSubmit = (data: SubmitParams) => Promise<void>;

export type SubmitParams = Omit<SendPayParams, 'session' | 'merchant'>;

const getMerchantAndSesstion = (data: {
  id: string | undefined;
  merchant: string | undefined;
  searchParams: URLSearchParams;
}) => {
  const { id, searchParams } = data;
  const session = id || searchParams.get('session') || '';
  const merchant = data.merchant || searchParams.get('merchant') || '';
  return { session, merchant };
};

export type TransactionsIdItems = {
  transactionId: string;
  status: 'setteld' | 'pending' | 'success' | 'error';
};

export const useOnSubmit = (params: OnSubmitParams) => {
  const { id } = useParams();
  const {
    state: { checkData }
  } = useCheckContext();
  const { redirectPaths, merchant, token } = params;
  const [idTransaction, setIdTransaction] = useState<TransactionsIdItems>({
    transactionId: id || '',
    status: 'setteld'
  });
  const { pathname, search } = useLocation();
  const fullUrl = `${window.location.origin}${pathname}${search}`;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({ session: '', merchant: '' });

  const merchanAndSession = useMemo(
    () =>
      getMerchantAndSesstion({
        id,
        merchant,
        searchParams
      }),
    [id, merchant, searchParams]
  );

  const { fingerPrint } = useFingerPrint();

  useUserEncodeData(merchanAndSession.session, fingerPrint);

  const onSubmit = useCallback(
    (data: SubmitParams) => {
      const { card_number, expiry } = data;
      setIdTransaction((prev) => ({ ...prev, status: 'pending' }));

      let sendedData: SendPayParams = {
        ...data,
        ...merchanAndSession,
        card_number: formatSendCardNuber(card_number),
        expiry: formatSendExpiry(expiry)
      };

      if (token) {
        sendedData.token = token;
      }
      if (checkData) {
        sendedData = { ...sendedData, ...checkData };
      }
      const retrySession = sessionStorage.getItem('retrySession');
      if (retrySession) {
        const parsedRetrySession = JSON.parse(retrySession);
        sendedData = {
          ...sendedData,
          ...parsedRetrySession
        };
      }

      return sendPay(sendedData, fingerPrint)
        .then((res) => {
          const { url, creq, pareq, isRetryPossible, retryData } = res;
          if (isRetryPossible === true) {
            const retrySessionData = {
              isRetryTrx: true,
              retryHash: retryData?.hash
            };
            sessionStorage.setItem(
              'retrySession',
              JSON.stringify(retrySessionData)
            );
            return navigate('/retry', { state: { retryData, id, fullUrl } });
          }
          if (creq || pareq) {
            getFakeForm({ ...res });
          } else {
            window.location.replace(url);
          }
        })
        .catch(() => {
          navigate(redirectPaths.failed(id));
        });
    },
    [
      fingerPrint,
      id,
      merchanAndSession,
      navigate,
      redirectPaths,
      token,
      checkData
    ]
  );

  return { onSubmit, idTransaction };
};
