export const CREDITSEND_MERCHANT = '10';

export const avansVerify = (param?: string) => {
  if (param) {
    return `/${CREDITSEND_MERCHANT}/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const avansVerifySuccess = (param?: string) => {
  if (param) {
    return `/${CREDITSEND_MERCHANT}/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const avansVerifyFailed = (param?: string) => {
  if (param) {
    return `/${CREDITSEND_MERCHANT}/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};
