import style from './accordion.module.scss';

export const AccordionComponent = () => {
  return (
    <>
      <div className={style.scrollableContainer}>
        {/* <textarea
          className={style.textarea}
          defaultValue={textInForm}
          readOnly
        ></textarea> */}

        <textarea className={style.textarea}>
          Зазначаючи реквізити електронного платіжного засобу (платіжної картки)
          для безготівкового перерахування коштів на рахунок, списання коштів із
          рахунку (погашення кредиту), позичальник усвідомлює, що ці реквізити
          зберігатимуться кредитодавцем або особою, з якою в кредитодавця є
          договірні відносини, та можуть використовуватися для списання коштів з
          рахунку для погашення вимог за договором про споживчий кредит. У разі
          настання такого списання позичальник має право в односторонньому
          порядку відмовитися або припинити списання коштів у визначеному
          договором порядку
        </textarea>
      </div>
    </>
  );
};
