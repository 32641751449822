import { Children, isValidElement, cloneElement } from 'react';
import style from '@root/scss/credit7/index.module.scss';
import { K7Form } from './K7Form';
import { useParams } from 'react-router-dom';
import { useUserData } from '@root/queries/useUserData';
import { useOnSubmit, OnSubmitParams } from '@root/hooks/useOnSubmit';
import { ContentData } from '@root/constants/formData/types';
import { Oval } from 'react-loader-spinner';
import ErrorRedirect from '~/components/ErrorRedirect/ErrorRedirect';

type MainK7Props = {
  mainContent: ContentData['main'];
  children?: React.ReactNode;
  formData: {
    title: string;
  };
  redirectPaths: OnSubmitParams['redirectPaths'];
};

export const MainK7 = (props: MainK7Props) => {
  const { redirectPaths, children, formData, mainContent } = props;
  const { id } = useParams();
  const { userData, errorInfo } = useUserData(id);
  const { onSubmit, idTransaction } = useOnSubmit({
    redirectPaths,
    merchant:
      userData && userData.options.merchant ? userData.options.merchant : '',
    token: userData ? userData.token : undefined
  });

  if (errorInfo) {
    return <ErrorRedirect errorInfo={errorInfo} classBtn="btn-bg" />;
  }

  if (userData) {
    return (
      <K7Form
        idTransaction={idTransaction}
        onSubmit={onSubmit}
        formData={formData}
        mainContent={mainContent}
        userData={userData}
      >
        {children
          ? Children.map(children, (child) => {
              if (isValidElement<{ userData: typeof userData }>(child)) {
                return cloneElement(child, { userData });
              }
            })
          : children}
      </K7Form>
    );
  }

  return (
    <section className={style.blockForm}>
      <div className={style.container_540}>
        <Oval wrapperClass={style['centred-block']} />
      </div>
    </section>
  );
};
