import masterCard from '@images/sunCredit/mastercard.svg';
import visa from '@images/sunCredit/visa.svg';

export const getCardType = (cardNumber: string) => {
  const number = cardNumber.replace(/\s+/g, '');
  if (/^4/.test(number)) {
    return visa;
  } else if (
    /^5[1-5]/.test(number) ||
    /^2(2[2-9]|[3-6][0-9]|7[01])/.test(number)
  ) {
    return masterCard;
  } else {
    return '';
  }
};
