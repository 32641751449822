import successIcon from '@images/starfin/successField.svg';
import style from '@root/scss/avansCredit/index.module.scss';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CommitionWarning from '~/components/CommitionWarning';
import CoverFormComponent from '~/components/CoverFormComponent';
import PayButtons from '~/components/PayButtons';
import UserAgreement from '~/components/UserAgreement';
import { errorTextValidation } from '~/pages/creditPlus/constats/errorTextValidation';
import {
  isDisabledCardField,
  isDisabledExpiryField
} from '~/utils/disableCardFields';
import {
  excludeValidationForSomeCards,
  useValidation
} from '~/utils/validation';
import { AccordionComponent } from '../AvansCredit/components/Accordion';
import {
  FormInputs,
  initialValues,
  Props
} from '../AvansCredit/components/helper/helper';
import { FormField } from './FormField';
import { PaymentDetails } from './PaymentDetails';

export const Form = ({ userData, onSubmit, urlContainsPay }: Props) => {
  const { cardValidation, isValidate, setIsValidate } = useValidation({
    noValidateCardNumber: isDisabledCardField(
      userData.cardMask,
      userData.editCardMask
    ),
    noValidateExpiry: isDisabledExpiryField(
      userData.expiry,
      userData.editCardMask
    ),
    validationText: errorTextValidation,
    validateEmail: true
  });

  const {
    watch,
    control,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useForm<FormInputs>({
    resolver: cardValidation,
    defaultValues: initialValues(userData)
  });

  useEffect(() => {
    const subscription = watch((value) => {
      if (excludeValidationForSomeCards(value['card_number'])) {
        setIsValidate(false);
      } else if (!isValidate) {
        setIsValidate(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, isValidate, setIsValidate]);

  useEffect(() => {
    if (cardValidation === undefined) {
      clearErrors();
    }
  }, [cardValidation, clearErrors]);

  const handleFormSubmit = (data: FormInputs) => {
    onSubmit(data);
  };
  const { t } = useTranslation(['common']);
  const currentUrl = window.location.href;

  return (
    <>
      <section className="form-container starfin-container">
        <div className="accordion">
          <AccordionComponent />
        </div>

        {urlContainsPay ? null : <PaymentDetails userData={userData} />}

        <form onSubmit={handleSubmit(handleFormSubmit)} className="form">
          <CoverFormComponent isLoading={isSubmitting} />

          <div className="card-block">
            <div className="card-container">
              <div className="card">
                <div className="card-content">
                  <div className={style.blockForm}>
                    <span className="label">Номер картки:</span>
                    <FormField
                      disabled={
                        isDisabledCardField(
                          userData.cardMask,
                          userData.editCardMask
                        ) || isSubmitting
                      }
                      successIcon={successIcon}
                      control={control}
                      name={'card_number'}
                      mask={
                        userData.cardMask
                          ? '9999 XXXX XXXX 9999'
                          : '9999 9999 9999 9999'
                      }
                      error={errors['card_number']}
                      placeholder="Введіть 16 цифр"
                    />
                    <div className={style.expiryBlock}>
                      <span className="label">Термін дії:</span>
                      <FormField
                        disabled={Boolean(userData.expiry)}
                        control={control}
                        name={'expiry'}
                        mask={'99/99'}
                        error={errors['expiry']}
                        placeholder="00/00"
                        successIcon={successIcon}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card2">
                <div className="card2-bgc"></div>
                <div className={style.blockForm}>
                  <div className="cvv-block">
                    <div className="label-placeholder">
                      Три цифри на зворотній стороні картки
                    </div>
                    <div style={{ width: '100px' }}>
                      <p className="label">CVV код:</p>
                      <FormField
                        control={control}
                        name={'cvv'}
                        mask={'999'}
                        error={errors['cvv']}
                        maskChar=""
                        type="password"
                        placeholder="XXX"
                        successIcon={successIcon}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-block-starfin">
              <button
                type="submit"
                className={`btn-starfin ${
                  urlContainsPay ? '' : 'pay-class-starfin'
                }`}
              >
                {urlContainsPay ? 'Перевірити' : 'Сплатити'}
              </button>

              <div className="pay-block-starfin">
                <PayButtons
                  isApple={userData.apayEnabled}
                  isGoogle={userData.gpayEnabled}
                  payInfo={{
                    fee: userData.fee,
                    clientName: userData.options.client_name,
                    agreementId: userData.options.agreement_id,
                    fullAmount: userData.fullAmount,
                    payAmount: userData.payAmount
                  }}
                  onSubmit={onSubmit}
                />
              </div>

              <div className="starfin-user-aggre-block">
                <UserAgreement
                  textButton={
                    currentUrl.includes('verify') ? 'Перевірити' : 'Сплатити'
                  }
                />
                {!currentUrl.includes('verify') && (
                  <>
                    <CommitionWarning />
                    <CommitionWarning text={t('common:bank_commition')} />
                  </>
                )}
              </div>

              <div
                className="protocol-starfin"
                style={{ marginTop: urlContainsPay ? '15px' : '0' }}
              >
                Ваші дані надійно захищені 256-бітовим SSL-протоколом
              </div>
            </div>
          </div>

          {/* <UserAgreement /> */}
        </form>
      </section>
    </>
  );
};
