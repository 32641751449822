import { SendPayPost } from '@root/api/getUserInfo';

export const getFakeForm = (data: SendPayPost) => {
  const { url, ...rest } = data;
  const form = document.createElement('form');
  document.body.appendChild(form);
  form.method = 'POST';
  form.action = url;

  Object.entries(rest).forEach((field) => {
    const [name, value] = field;

    // Проверяем и преобразуем значение в строку, если это возможно
    if (value !== undefined && value !== null) {
      let stringValue: string;

      if (typeof value === 'string') {
        stringValue = value;
      } else if (typeof value === 'boolean') {
        stringValue = value.toString();
      } else if (typeof value === 'object') {
        stringValue = JSON.stringify(value);
      } else {
        console.warn(`Значение для ${name} не поддерживается:`, value);
        return; // Пропускаем неподдерживаемые значения
      }

      // Создаем скрытое поле формы
      createInputFormFields(form, name, stringValue);
    }
  });

  form.submit();
  form.remove();
};

function createInputFormFields(
  form: HTMLFormElement,
  name: string,
  value: string
) {
  const element = document.createElement('INPUT') as HTMLInputElement;
  element.name = name;
  element.value = value;
  element.type = 'hidden';
  form.appendChild(element);
}
