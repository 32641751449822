import { lazy } from 'react';
import {
  avansVerifyFailed,
  avansVerifySuccess
} from '~/constants/paths/pathAvans';
import { CREDITSEND_MERCHANT } from '~/constants/paths/pathCreditSend';
import logo from '~/images/creditSend/Group 14@2x.svg';
import { AvansMain } from '~/pages/AvansCredit/components/AvansMain';

const LayoutAvansCredit = lazy(
  () => import('~/pages/AvansCredit/components/Index')
);
export const CreditSendBarRoute = {
  path: CREDITSEND_MERCHANT,
  element: (
    <LayoutAvansCredit logo={logo} phone="0800 20 10 80" merchant="CrediSend" />
  ),
  children: [
    {
      path: 'verify/:id',
      element: (
        <AvansMain
          redirectPaths={{
            success: avansVerifySuccess,
            failed: avansVerifyFailed
          }}
        />
      )
    }
  ]
};
