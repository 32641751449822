import { getPaytechContentData } from '@root/constants/formData';
import { lazy } from 'react';
import RetryStatus from '~/components/RetryStatus';

const CustomLayout = lazy(() => import('@root/components/CustomLayout'));

const { header, footer } = getPaytechContentData();

export const statusPageRouteRetry = {
  path: 'retry',
  element: <CustomLayout headerContent={header} footerContent={footer} />,
  children: [
    {
      index: true,
      element: <RetryStatus />
    }
  ]
};
