import style from '@root/scss/avansCredit/index.module.scss';

import classNames from 'classnames';
import { Control, Controller, FieldError, useWatch } from 'react-hook-form';
import InputMask from 'react-input-mask';

type K7FieldProps = {
  name: string;
  control: Control<any>;
  mask: string | string[];
  label?: string;
  isIcon?: boolean;
  error: FieldError | undefined;
  disabled?: boolean;
  inputMode?: 'text' | 'numeric' | 'decimal' | 'none';
  maskChar?: string;
  type?: 'text' | 'password';
  errorIcon?: string;
  successIcon?: string;
  placeholder?: string;
};

export const FormField = (props: K7FieldProps) => {
  const {
    name,
    control,
    label,
    mask,
    error,
    isIcon = false,
    disabled = false,
    inputMode = 'numeric',
    maskChar = '',
    type = 'text',
    errorIcon,
    placeholder,
    successIcon
  } = props;
  const namedValue = useWatch({ control, name });

  const inputBoxStyles = [style.input_box, isIcon ? style.input_box_icon : '']
    .join(' ')
    .trim();

  return (
    <div
      className={classNames(inputBoxStyles, {
        [style.invalid]: Boolean(error),
        [style.not_empty]: Boolean(namedValue)
      })}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, name }, fieldState }) => {
          const isMaskComplete =
            value && mask instanceof Array
              ? mask.every((m) => m.length === value.length)
              : value?.length === mask.length;

          return (
            <>
              <InputMask
                name={name}
                inputMode={inputMode}
                disabled={disabled}
                mask={mask}
                type={type}
                value={value}
                onChange={onChange}
                autoComplete="on"
                id={name}
                maskChar={maskChar}
                placeholder={placeholder ? placeholder : ''}
              >
                {
                  // @ts-ignore
                  (inputProps) => (
                    <input {...inputProps} className="sunCredit-input" />
                  )
                }
              </InputMask>

              {isMaskComplete && !fieldState.error && (
                <img
                  className={style.success_img_sunCredit}
                  src={successIcon}
                  alt="Success"
                />
              )}
              {fieldState.error && (
                <p className={style.error_text}>{fieldState.error.message}</p>
              )}
              {fieldState.error && (
                <img className={style.error_img} src={errorIcon} alt="" />
              )}
            </>
          );
        }}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
};
