import successIcon from '@images/sunCredit/successIcon.svg';
import style from '@root/scss/avansCredit/index.module.scss';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CommitionWarning from '~/components/CommitionWarning';
import CoverFormComponent from '~/components/CoverFormComponent';
import PayButtons from '~/components/PayButtons';
import UserAgreement from '~/components/UserAgreement';
import { errorTextValidation } from '~/pages/creditPlus/constats/errorTextValidation';
import {
  isDisabledCardField,
  isDisabledExpiryField
} from '~/utils/disableCardFields';
import {
  excludeValidationForSomeCards,
  useValidation
} from '~/utils/validation';
import {
  FormInputs,
  initialValues,
  Props
} from '../AvansCredit/components/helper/helper';
import { AccordionComponentSunCredit } from './AccordionSunCredit';
import { FormField } from './FormField';
import { getCardType } from './helper';
import { PaymentDetails } from './PaymentDetails';
import './sunCredit.scss';

export const Form = ({ userData, onSubmit, urlContainsPay }: Props) => {
  const { cardValidation, isValidate, setIsValidate } = useValidation({
    noValidateCardNumber: isDisabledCardField(
      userData.cardMask,
      userData.editCardMask
    ),
    noValidateExpiry: isDisabledExpiryField(
      userData.expiry,
      userData.editCardMask
    ),
    validationText: errorTextValidation,
    validateEmail: true
  });

  const {
    watch,
    control,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting, isValid }
  } = useForm<FormInputs>({
    resolver: cardValidation,
    defaultValues: initialValues(userData),
    mode: 'onChange'
  });
  const watchFields = watch('card_number');
  const [typeCard, setTypeCard] = useState('');

  useEffect(() => {
    const subscription = watch((value) => {
      if (excludeValidationForSomeCards(value['card_number'])) {
        setIsValidate(false);
      } else if (!isValidate) {
        setIsValidate(true);
      }
    });
    const cardTypeDetected = getCardType(watchFields);
    setTypeCard(cardTypeDetected);
    return () => subscription.unsubscribe();
  }, [watch, isValidate, setIsValidate, watchFields]);

  useEffect(() => {
    if (cardValidation === undefined) {
      clearErrors();
    }
  }, [cardValidation, clearErrors]);

  const handleFormSubmit = (data: FormInputs) => {
    onSubmit(data);
  };
  const { t } = useTranslation(['common']);
  const currentUrl = window.location.href;

  return (
    <>
      <section className="form-container">
        <div className="accordion">
          <AccordionComponentSunCredit />
        </div>

        {urlContainsPay ? null : <PaymentDetails userData={userData} />}

        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="forn-suncredit"
        >
          <CoverFormComponent isLoading={isSubmitting} />

          <div className="card-block">
            <div className="card-container">
              <div className="card">
                <div className="card-content-suncredit">
                  <div className={style.blockForm}>
                    <p className="label">
                      <p>Номер картки:</p>
                      <p>
                        <img src={typeCard} alt="" />
                      </p>
                    </p>
                    <FormField
                      disabled={
                        isDisabledCardField(
                          userData.cardMask,
                          userData.editCardMask
                        ) || isSubmitting
                      }
                      control={control}
                      name={'card_number'}
                      mask={
                        userData.cardMask
                          ? '9999 XXXX XXXX 9999'
                          : '9999 9999 9999 9999'
                      }
                      successIcon={successIcon}
                      error={errors['card_number']}
                      placeholder="Введіть 16 цифр"
                    />
                    <div className={style.expiryBlock}>
                      <p className="label">Термін дії:</p>
                      <FormField
                        disabled={Boolean(userData.expiry)}
                        control={control}
                        name={'expiry'}
                        mask={'99/99'}
                        error={errors['expiry']}
                        placeholder="00/00"
                        successIcon={successIcon}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card2">
                <div className="card2-bgc"></div>
                <div className={style.blockForm}>
                  <div className="cvv-block">
                    <div className="label-placeholder">
                      Три цифри на зворотній стороні картки
                    </div>
                    <div style={{ width: '100px' }}>
                      <p className="label">CVV код:</p>
                      <FormField
                        control={control}
                        name={'cvv'}
                        mask={'999'}
                        error={errors['cvv']}
                        maskChar=""
                        type="password"
                        placeholder="XXX"
                        successIcon={successIcon}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className={`btn-sunCredit ${isValid ? 'valid' : 'invalid'}`}
          >
            {urlContainsPay ? 'Перевірити' : 'Сплатити'}
          </button>

          <div className="pay-block sunCredit-btn">
            <PayButtons
              isApple={userData.apayEnabled}
              isGoogle={userData.gpayEnabled}
              payInfo={{
                fee: userData.fee,
                clientName: userData.options.client_name,
                agreementId: userData.options.agreement_id,
                fullAmount: userData.fullAmount,
                payAmount: userData.payAmount
              }}
              onSubmit={onSubmit}
            />
          </div>
          <div className="sucredit-user-aggre-block">
            <UserAgreement
              textButton={
                currentUrl.includes('verify') ? 'Перевірити' : 'Сплатити'
              }
            />
            {!currentUrl.includes('verify') && (
              <>
                <CommitionWarning />
                <CommitionWarning text={t('common:bank_commition')} />
              </>
            )}
          </div>

          <div className="protocol-sun">
            Ваші дані надійно захищені 256-бітовим SSL-протоколом
          </div>
        </form>
      </section>
    </>
  );
};
