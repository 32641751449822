import LoaderComponent from '@comp/LoaderComponet';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { retryTran } from '~/api/getUserInfo';

const RetryStatus = () => {
  const { t } = useTranslation(['common']);
  const location = useLocation();
  const handleRetry = () => {
    window.location.replace(location.state.fullUrl);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        await retryTran(
          location.state.retryData.frameDataId,
          location.state.retryData.hash
        );
      } catch (err: any) {
        console.log('Error');
      }
    };

    fetchData();
  }, []);

  if (location.state) {
    return (
      <section className="cardProof">
        <button className="btn" type="submit" onClick={handleRetry}>
          {t('common:retry')}
        </button>
      </section>
    );
  }

  return <LoaderComponent />;
};

export default RetryStatus;
